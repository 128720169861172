.mat-mdc-table,
.mat-mdc-paginator-container,
.mat-expansion-panel {
  background-color: #fff !important;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}

.mat-mdc-select-panel {
  min-width: calc(100% + 32px);
}

.mat-datepicker-input {
  font-family: 'FordAntenna', sans-serif !important;
}

.mat-datepicker-content-touch .mat-datepicker-content-container {
  height: fit-content !important;
}

.mat-mdc-accent .mat-mdc-pseudo-checkbox-checked,
.mat-mdc-accent .mat-mdc-pseudo-checkbox-indeterminate,
.mat-mdc-pseudo-checkbox-checked,
.mat-mdc-pseudo-checkbox-indeterminate {
  background: #0276b3;
}

.mat-mdc-primary .mat-mdc-pseudo-checkbox-checked,
.mat-mdc-primary .mat-mdc-pseudo-checkbox-indeterminate {
  background: #002f6c;
}

.mat-mdc-warn .mat-mdc-pseudo-checkbox-checked,
.mat-mdc-warn .mat-mdc-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-mdc-pseudo-checkbox-checked.mat-mdc-pseudo-checkbox-disabled,
.mat-mdc-pseudo-checkbox-indeterminate.mat-mdc-pseudo-checkbox-disabled {
  background: #b0b0b0;
  display: none;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: 15px;
}

.mat-mdc-radio-button.mat-mdc-radio-disabled .mat-mdc-radio-outer-circle,
.mat-mdc-radio-button.mat-mdc-radio-disabled.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button.mat-mdc-radio-disabled .mat-mdc-radio-inner-circle,
.mat-mdc-radio-button.mat-mdc-radio-disabled .mat-mdc-radio-ripple .mat-mdc-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}

/** Slide Toggle */
.mat-mdc-slide-toggle {
  .mdc-form-field.mat-internal-form-field {
    .mdc-switch .mdc-switch__track {
      width: 36px;
      height: 14px;
      border: none;
    }

    .mdc-switch:enabled .mdc-switch__track::after {
      background-color: #0273b1;
    }

    .mdc-switch--unselected .mdc-switch__handle-track .mdc-switch__handle {
      transform: translate(-14px, -6px);
    }

    .mdc-switch--unselected.mdc-switch--disabled .mdc-switch__track {
      background-color: #0273b1;
    }

    .mdc-switch:disabled .mdc-switch__track {
      opacity: 0.12;
    }

    .mdc-switch:disabled .mdc-switch__track::before {
      background-color: #424242;
    }

    .mdc-switch.mdc-switch--unselected .mdc-switch__track::before {
      border: none;
    }

    .mdc-switch__handle-track {
      height: 12px;
      width: 12px;
    }
    .mdc-switch__icons {
      opacity: 0;
    }

    .mdc-switch__handle {
      width: 12px;
      height: 12px;
      left: 11px;
      top: 7px;
    }

    .mdc-switch--selected.mdc-switch--checked .mdc-switch__handle {
      left: -13px;
    }

    .mdc-switch__shadow {
      background-color: white;
    }

    .mdc-switch:enabled .mdc-switch__shadow {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }

    .mdc-switch__ripple {
      width: 12px;
      height: 12px;
    }
  }
}

.mat-mdc-slide-toggle:active .mdc-switch:not(.mdc-switch--disabled) .mdc-switch__handle {
  width: 12px !important;
  height: 12px !important;
}

/** Mat Icon */
.mat-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: scale(0.7);
  overflow: visible !important;
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
}

.btnCloseMessage {
  background-color: #d2d2d2;
  color: white;
  width: 20px;
  height: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
}

/** Checkbox */
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  color: #002f6c !important;
}
.mat-mdc-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-mdc-accent .mat-mdc-pseudo-checkbox-checked,
.mat-mdc-accent .mat-mdc-pseudo-checkbox-indeterminate,
.mat-mdc-pseudo-checkbox-checked,
.mat-mdc-pseudo-checkbox-indeterminate {
  background: #475776;
}

.mat-mdc-primary .mat-mdc-pseudo-checkbox-checked,
.mat-mdc-primary .mat-mdc-pseudo-checkbox-indeterminate {
  background: #475776;
}

.mat-mdc-warn .mat-mdc-pseudo-checkbox-checked,
.mat-mdc-warn .mat-mdc-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-mdc-pseudo-checkbox-checked.mat-mdc-pseudo-checkbox-disabled,
.mat-mdc-pseudo-checkbox-indeterminate.mat-mdc-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-mdc-option {
  font-family: 'FordAntenna', 'Roboto', 'Helvetica Neue', 'sans-serif';
}

// aqui precisamos aumentar a especificidade desse seletor para sobreescrever o padrão do material
.mat-mdc-dialog-container .mdc-dialog__content,
.mat-mdc-dialog-container.mat-mdc-dialog-container {
  font-family: 'FordAntenna', 'Roboto', 'Helvetica Neue', sans-serif !important;
}

.mat-mdc-dialog-container.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px !important;
  font-family: 'FordAntenna', 'Roboto', 'Helvetica Neue', sans-serif !important;
}

/// aqui fica a ressalva de termos de usar um seletor de elementos e, ainda pior, !important
/// o componente de espansão do material adiona o height como inline style quando o componente é aberto ou fechado
/// por isso essa medida drástica
mat-expansion-panel-header {
  height: auto !important;
}

.mat-mdc-expansion-panel {
  box-shadow: none;
  color: inherit;
}

.mat-mdc-expansion-panel-header {
  padding: 0 24px;
  font-family: 'FordAntenna', Roboto, 'Helvetica Neue', sans-serif;
}

.mat-mdc-expansion-panel-content {
  font-family: 'FordAntenna', Roboto, 'Helvetica Neue', sans-serif;
}

/// sobreescrevendo o background usado quando há o hover (mais uma vez, foi necessário usar um seletor com alta especificidade porque o default
/// já é bem específico)
.mat-mdc-expansion-panel:not(.mat-mdc-expanded) .mat-mdc-expansion-panel-header:not([aria-disabled='true']):hover {
  background: inherit;
}

/// aqui foi necessário aumentar a especificidade do seletor para sobreescrever o padrão do material
mat-expansion-panel-header span.mat-mdc-content {
  flex: 0 1 auto;
}

mat-expansion-panel-header.collapse__header span.mat-mdc-content {
  display: block;
  flex: 1;
}

/// sobreescrevendo a cor do texto usada no expansion panel
.mat-mdc-expansion-panel-header-title {
  color: inherit;
}

.mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar,
.mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar {
  background-color: #002f6c;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #002f6c !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #002f6c !important;
  font-weight: 600 !important;
}

.mat-mdc-tab .mdc-tab__text-label {
  font-family: 'FordAntenna' !important;
}

mat-button-toggle-group {
  border-radius: 4px !important;
  border-color: #e0e0e0 !important;

  .mat-button-toggle {
    font-family: 'FordAntenna';
    font-size: 16px;
    font-weight: 400;
    border: 1px solid hsl(217, 33%, 83%);

    &.mat-button-toggle-checked {
      background-color: #e0e0e0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0 5px 0 0 !important;
  }
}

// Bootstrap overrides

a {
  text-decoration: none !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  font-family: 'FordAntenna', sans-serif;
}

.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  font-family: 'FordAntenna', sans-serif;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
