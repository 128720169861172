/* hiagoforte - CSS utilizado para substituir quaisquer parâmetros do bootstrap */
/* @mediaQuery foi utilizado para definir layouts acima/abaixo de 600px */

/* Responsividade */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$grid-breakpoints-cards: (
  xs: 0px,
  sm: 680px,
  md: 992px,
  lg: 1330px,
  xl: 1640px,
);

$color-inactive: #888f9d;
$color-active: #0276b3;

$color-primary: #002f6c;
$color-primary-hover: #094696;
$color-primary-active: #1d5cad;

$color-outline: #dce5f4;
$color-outline-hover: #c6d4eb;
$color-outline-active: #b9c6dd;

.btn {
  border-radius: 4px;
  border: 2px solid transparent;
  margin-right: 20px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}

.btn.btn-menu {
  width: 147px;
  padding: 10px;
  font-weight: 500;
}

.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
  color: white;
  &:hover {
    background-color: $color-primary-hover;
    border-color: $color-primary-hover;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $color-primary-active;
    border-color: $color-primary-active;
  }
  &:not(:disabled):not(.disabled):focus {
    box-shadow: none;
  }
}

.btn-outline-primary {
  background-color: $color-outline;
  color: $color-primary;
  border-color: $color-primary;
  &:hover {
    background-color: $color-outline-hover;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $color-outline-active;
    color: $color-primary;
  }
  &:not(:disabled):not(.disabled):focus {
    box-shadow: none;
    border-color: $color-primary;
  }
}

.btn-selectable-primary {
  background-color: transparent;
  color: #002f6c;
  border-color: #002f6c;
  font-family: 'FordAntenna';
  font-weight: 500;
  &:hover {
    background-color: #002f6c;
    color: #ffffff;
  }
  &:focus {
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled):active,
  &.active {
    background-color: $color-primary;
    color: white;
  }
  &.active:not(:disabled):not(.disabled):focus {
    box-shadow: none;
    border-color: $color-primary;
    color: white;
  }
  &:not(.active):not(:active):not(:disabled):not(.disabled):not(:hover):focus {
    border-color: $color-primary;
    color: $color-primary;
  }
  &.btn-sm {
    padding: 2px 10px;
    border-width: 1px;
  }
}

.btn-group {
  & > .btn-selectable-primary {
    border-radius: 4px;
    margin-right: 10px;
  }
}

.colorCustomer {
  color: #102b4e;
}

.ajust-filter {
  margin-right: 15px;
}

@media screen and (max-width: 500px) {
  .btn-mobile {
    width: 100%;
    margin-right: 0;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container {
  max-width: 1550px;
}

@media screen and (min-width: 900px) {
  .generalPadding {
    padding: 2.25rem !important;
  }
}

@media screen and (max-width: 400px) {
  .generalPadding {
    padding: 1.25rem !important;
  }
}

@media screen and (min-width: 900px) {
  .containerTab {
    margin: 0 auto;
    width: 800px;
  }
}

.detailsCarImg {
  width: 26%;
  margin-left: 37%;
  position: unset;
}

@media only screen and (max-width: 450px) {
  .detailsCarImg {
    width: 50%;
    margin-left: 25%;
    position: unset;
  }

  .btnCenter {
    text-align: center;
  }
}

.detailsAvatarImg {
  width: 22%;
  margin-left: 39%;
  position: unset;
}

@media only screen and (max-width: 400px) {
  .detailsAvatarImg {
    width: 50%;
    margin-left: 25%;
    position: unset;
  }
}

.logo {
  height: 50px;
  width: 128px;
}

.defaultCar {
  height: 110px;
  width: 100%;
  margin-right: 0px;
  margin-top: 0px;
  text-align: center;
}

.defaultCarImg {
  max-height: 100%;
  max-width: 100%;
  position: relative;
  top: 10%;
  // margin-top: -43px; __<<ngThemingMigrationEscapedComment3>>__
}

.defaultNoneCar {
  width: 60px;
  max-width: 100%;
  /* margin-right: 7px; */
  /* margin-top: 10px; */
  margin: 39.5px auto;
  opacity: 1;
  border-radius: 50%;
  /* background-color: #9ea8bf5e;*/
}

.availableSchedule {
  color: #2861a4;
  border-top-color: #8390ac;
  border-top-width: 2px;
  font-size: 0.85rem;
  font-weight: 500;
  font-family: 'FordAntenna';
  padding-bottom: 0;
  margin: 0 10px auto 10px;
}
.scheduleHourAlign {
  position: absolute;
  bottom: 10px;
}
.defaultCarOpacity {
  opacity: 0.2;
}

@media screen and (max-width: 600px) {
  .availableSchedule {
    color: #2861a4;
    border-top-color: #8390ac;
    border-top-width: 2px;
    font-size: 0.95rem;
    font-weight: 600;
    margin-bottom: 9px;
    // margin-top: -10px;
    font-family: 'FordAntenna';
  }

  .defaultCar {
    height: 76px;
    width: 127px;
    max-width: 100%;
    margin-right: 6px;
    margin-top: 0px;
  }
  .defaultNoneCar {
    height: 76px;
    width: 127px;
    max-width: 100%;
    margin-right: 6px;
    margin-top: 0px;
    opacity: 0.2;
    border-radius: 7px;
    background-color: #9ea8bf5e;
  }

  .scheduleHourAlign {
    position: relative;
    bottom: -2px;
  }
}

@media screen and (max-width: 380px) {
  .defaultCar {
    height: 79px;
    width: 106px;
    max-width: 100%;
    margin-right: 4px;
    margin-top: 0px;
  }

  .defaultNoneCar {
    height: 69px;
    width: 99px;
    max-width: 100%;
    margin-right: 6px;
    margin-top: 0px;
    opacity: 0.2;
    border-radius: 7px;
    background-color: #9ea8bf5e;
  }

  .scheduleHourAlign {
    position: relative;
    bottom: -2px;
  }
}

.align_text {
  text-align: center;
}

.nameCarBoard {
  color: #b1bac9;
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  font-family: 'FordAntenna';
}

.nameActivity {
  color: #818181;
  font-size: 13px;
  font-family: inherit;
  font-weight: 400;
  font-family: 'FordAntenna';
}

.nameTechHour {
  color: #003478;
  font-size: 18px;
  font-family: inherit;
  font-weight: 400;
}

.footer-background {
  background-color: #ffffff;
  border-top: none;
}

.titleNav {
  color: #003478;
  border-top-color: #8390ac;
  border-top-width: 2px;
  font-size: 13px;
  margin-top: 8px;
}

.nav-tab {
  // border-bottom: 1px solid #dee2e6;
  border-bottom: none;
  width: 100%;
  padding-bottom: 0px;
}

.iconsTab {
  height: 14px;
  width: 14px;
  margin-bottom: 6px;
  opacity: 0.4;
}

.iconSchedule {
  width: 17px;
  height: 17px;
  margin-right: 0px;
  margin-left: 1px;
  margin-top: 8px;
  position: absolute;
}

.iconsTabCar {
  height: 17px;
  width: 29px;
  margin-bottom: 6px;
  opacity: 0.4;
}

.rowStyle {
  color: #475776;
  padding-left: 16px;
  padding-bottom: 13px;
  font-family: 'FordAntenna';
  font-size: 13px;
  font-weight: 500;
  margin-left: -8px;
}

@media only screen and (max-width: 360px) {
  .rowStyle {
    font-size: 13px;
  }
  .scheduleHourAlign {
    position: relative;
    bottom: -2px;
  }
}

.showLess {
  width: 100%;
  margin: 0px 0px -10px 0px;
  max-width: 100%;
  align-items: center;
  font-size: 11px;
  text-align: center;
  color: #003478;
  font-weight: 500;
  font-family: 'FordAntenna';
}

.backCard {
  transform: rotateX(180deg);
  width: 17px;
  height: 17px;
  color: #596783;
}

.iconCardDrop {
  height: 22px;
  width: 16px;
}

.iconBackCardDrop {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.card {
  &__columns {
    orphans: unset !important;
    margin-top: 25px;
  }

  &__inner {
    display: flex;
    flex-flow: column-reverse;
  }

  &__button {
    background-color: #002f6c;
    color: #ffffff;
    width: 100%;
    height: 40px;
    margin: 5px 0px 1px 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 14px;
    border-radius: 4px;
    font-family: 'FordAntenna';
  }

  &__buttonCL {
    width: 100%;
    height: 50px;
    margin: 5px 0px 1px 0px;
    font-size: 12px;
    color: #596783;
    background-color: transparent;
    border-radius: 4px;
    border: none;
    font-family: 'FordAntenna';
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }

  &__input {
    width: 100%;
    margin: 2px 0px 5px 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 14px;
    font-family: 'FordAntenna';
  }

  &__alertButton {
    width: 100%;
    height: 50px;
    margin: 5px 0px 1px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #002f6c;
    background-color: #e6eaf0;
    border: none;
    box-shadow: 0px 0px 5px #dee7f6cc;
    border-radius: 4px;
    font-family: 'FordAntenna';
  }

  @media screen and (max-width: 575px) {
    &__wrapper {
      width: 100%;
    }

    &__inner {
      flex-flow: row;
      //flex-flow: row-reverse;
    }
  }
}

.loader {
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-top: 150px;
  }

  &__image {
    width: 115px;
  }
}

.loaderTwo {
  &__imageSmall {
    width: 29px;
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-top: 0px;
    margin-bottom: 6px;
  }
}

/* Input referente à busca pela Placa/Chassi */
input {
  outline: none;
}
input[type='search'] {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none !important;
}

input[type='search'] {
  background: #ffffff url(/home/assets/img/search.png) no-repeat 8px center;
  border: none;
  padding: 10px 8px 10px 19px;
  width: 250px;
  background-position-x: 94%;
  -webkit-appearance: none;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
}

@media screen and (max-width: 600px) {
  input[type='search'] {
    background: #ffffff url(/home/assets/img/search.png) no-repeat 8px center;
    border: none;
    padding: 9px 8px 9px 16px;
    width: 231px;
    font-family: 'FordAntenna';
    background-position-x: 94%;
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    border-radius: 10em;
  }
}

@media screen and (max-width: 320px) {
  input[type='search'] {
    background: #ffffff url(/home/assets/img/search.png) no-repeat 8px center;
    border: none;
    padding: 9px 8px 9px 16px;
    width: 176px;
    font-family: 'FordAntenna';
    background-position-x: 94%;
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    border-radius: 10em;
  }
}

/* Padrão utilizado no <span> Não é o condutor? </span> */
.conductor {
  font-family: 'FordAntenna';
  font-size: 15px;
  margin: 8px 0px 5px 0px;
}

/* Botão Filter */
.filterButton {
  background-color: rgba(16, 43, 78, 0.05);
  /* #102B4E;*/
  width: 36px;
  padding: 8px 9px 9px 9px;
  font-family: 'FordAntenna';
  position: relative;
  top: 180%;
  right: 0px;
  z-index: 1;
  transition: all 0.5s;
  cursor: pointer;
}

.filterButton.collapsed {
  padding: 8px 9px 9px 9px;
  width: 36px;
  margin-left: 5px;
  right: 0px;
  font-family: 'FordAntenna';
  position: static;
  top: 0px;
  background-color: transparent;
  cursor: pointer;
}

/* Botão Filter */
.filterButtonChecklist {
  padding: 8px 9px 9px 9px;
  width: 45px;
  transition: all 0.5s;
  margin-left: 5px;
  font-family: 'FordAntenna';
}

/* usados em todos os subcomponentes de schedules-details */
.vehicleLabel {
  height: 18px;
  font-family: 'FordAntenna';
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #939fb5;
}

.ulFordProtectDetails {
  padding-inline-start: 0px;
  list-style-type: none;
  font-size: 12px;
  font-family: 'FordAntenna';
}

/* Queries de cards para detalhamentos */
@media screen and (max-width: 350px) {
  .card-body-tabs {
    padding: 10px !important;
  }
}

@media screen and (max-width: 400px) {
  .vehicleLabel {
    font-size: 16px;
    font-family: 'FordAntenna';
  }

  .fw-light {
    font-size: 13px;
    font-family: 'FordAntenna';
  }
} // fim dos styles de schedules-details

/* */
#mySidenav {
  display: none;
}

#collapse_filter {
  .filterWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .filterInner {
    border: none;
    justify-content: space-between;
    position: relative;
    border-radius: 7px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0px 8px 0px;
    text-align: left;
    z-index: 0;

    &::before {
      content: '';
      width: 15px;
      height: 14px;
      display: block;
      background-color: #eef4ff;
      border-top: none;
      border-left: none;
      position: absolute;
      top: -8px;
      right: 12px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border-bottom-right-radius: 100%;
      border-top-left-radius: 4px;
      display: none;
    }
  }

  .btnFilter {
    line-height: 12px;
    height: 25px;
    background-color: #eef4ff;
    color: #003478;
    margin-right: 7px;
    border-radius: 4px;
    border: solid 1px;
    border-color: #003478;
    font-weight: 500;
    font-size: 14px;
  }

  .btnFilter.active {
    background-color: #003478;
    color: white;
  }
}

/* Menu (lateral) toggle */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0%;
  left: 34%;
  background-color: #e0eafb;
  overflow-x: hidden;
  padding-top: 38px;
  border-radius: 10px;
  float: right;
  font-family: 'FordAntenna';
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  font-family: 'FordAntenna';
}

.sidenav a:hover {
  color: #f1f1f1;
}

.e-input-group-icon.e-date-icon::before,
.e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e36a';
  font-family: 'e-icons';
  font-weight: 900;
  font-size: 14px;
}

.e-datepicker .e-model-header {
  background-color: #003478;
  color: #fff;
  cursor: default;
  display: block;
  padding: 10px 10px 10px 15px;
  top: 90vh !important;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: transparent;
  border-color: transparent;
  color: #003478;
  top: 90vh !important;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #003478;
  border: 1px solid #003478;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
  top: 90vh !important;
}

.e-input-group input.e-input,
.e-input-group textarea.e-input,
.e-input-group input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.e-input-group textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper textarea.e-input,
.e-input-group.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.e-input-group.e-control-wrapper textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
  border: 0 solid;
  border-width: 0;
  color: #003478;
  font-weight: 500;
}

.e-input-group-icon.e-date-icon,
.e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 16px;
  margin: 0 0 0px 0;
  min-height: 24px;
  min-width: 19px;
  outline: none;
}

.e-input-group input.e-input,
.e-input-group textarea.e-input,
.e-input-group input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.e-input-group textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper textarea.e-input,
.e-input-group.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.e-input-group.e-control-wrapper textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
  border: 0 solid;
  border-width: 0;
  color: #003478;
  font: Regular 16px/19px Ford Antenna;
  //width: 45%;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
  border-color: rgba(0, 0, 0, 0);
}

.e-input-group[_ngcontent-c1]:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-float-icon-left[_ngcontent-c1]:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap[_ngcontent-c1],
.e-input-group.e-control-wrapper[_ngcontent-c1]:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-float-icon-left[_ngcontent-c1]:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap[_ngcontent-c1],
.e-float-input.e-float-icon-left[_ngcontent-c1]:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap[_ngcontent-c1],
.e-float-input.e-control-wrapper.e-float-icon-left[_ngcontent-c1]:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap[_ngcontent-c1] {
  border-color: rgba(0, 0, 0, 0);
  width: 100% !important;
  padding: 3px 1px 1px 0px;
  margin-bottom: 0px !important;
  background-color: transparent;
  position: static;
  margin-left: 0px;
  margin-top: -3px;
}

.e-control input.e-input,
.e-control .e-input-group input,
.e-control .e-input-group input.e-input,
.e-control .e-input-group.e-control-wrapper input,
.e-control .e-input-group.e-control-wrapper input.e-input,
.e-control .e-float-input input,
.e-control .e-float-input.e-control-wrapper input,
.e-control.e-input-group input,
.e-control.e-input-group input.e-input,
.e-control.e-input-group.e-control-wrapper input,
.e-control.e-input-group.e-control-wrapper input.e-input,
.e-control.e-float-input input,
.e-control.e-float-input.e-control-wrapper input {
  box-sizing: content-box;
  width: 70% !important;
}

.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input:focus,
.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus,
.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input:focus,
.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus {
  border-bottom-color: transparent;
  border-top-color: transparent;
  width: 70% !important;
}

// botão para fechar o filtro (mobile)
.sidenav .closebtn {
  position: absolute;
  top: -20px;
  right: 28px;
  font-size: 38px;
  color: tomato;
}

@media screen and (max-height: 400px) {
  .sidenav {
    padding-top: 50px;
  }
  .sidenav a {
    font-size: 50px;
  }
}

@media screen and (max-width: 100px) {
  #collapse_filter {
    display: none;
  }
  #mySidenav {
    display: flex;
    float: right;
  }

  .titleFilter {
    font-family: 'FordAntenna';
    font-size: 17px;
    color: #003478;
    margin-top: 8px;
    font-weight: 500;
    padding-top: 13px;
  }

  .btnFilter {
    background-color: #e0eafb;
    font-family: 'FordAntenna';
    color: #002f6c;
    margin-right: 5px;
    border-radius: 5px;
    border: solid 1px #002f6c;
    margin-bottom: 5px;
    border-radius: 16px;
    opacity: 0.7;
  }

  .btnFilter.active {
    background-color: #002f6c;
    border-radius: 16px;
    color: white;
    opacity: 0;
  }

  .mobileFilter {
    padding: 10px 0px 0px 13px;
  }
}

// for toastr
#toast-container > div {
  opacity: 1;
}

.applyButton {
  background-color: #002f6c;
  width: 60%;
  margin: 5px 0px 5px 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 15px;
  border-radius: 6px;
  color: white;
  margin-left: 40px;
  font-family: 'FordAntenna';
  margin-left: 9%;
}

.iconsCalPer {
  width: 16px;
  height: 16px;
  margin-right: 1px;
  margin-left: 2.5px;
  margin-bottom: 4px;
}

/* Date filter style */
.dateInputFilter {
  border: solid 1px #0077b4;
  border-radius: 8px;
  border-right: none;
  background-color: #798ea0;
  color: #ebf2f7;
  font-family: 'FordAntenna';
}

.btnSrch {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: steelblue;
  margin-left: -8px;
  border-radius: 8px;
  border: none;
  font-family: 'FordAntenna';
}

@media only screen and (max-width: 600px) {
  .dateInputFilter {
    background-color: #e0eafb;
  }
}

// breakpoints para número de colunas
@media only screen and (max-width: 575px) {
  .card-columns {
    column-count: 1;
  }
}

@media only screen and (min-width: 360px) {
  .card-columns {
    column-count: 2;
  }
}

@media only screen and (min-width: 768px) {
  .card-columns {
    column-count: 3;
  }
}

@media only screen and (min-width: 992px) {
  .card-columns {
    column-count: 4;
  }
}

@media only screen and (max-width: 780px) {
  .deliveryCard {
    font-size: 11px;
    font-weight: bold;
    background-color: #fbeed9;
    padding: 1px 3px 1px 3px;
    border-radius: 4px;
    padding: 4px 4px 4px 4px;
    width: 17%;
    text-align: center;
    font-family: 'FordAntenna';
  }
}

@media only screen and (min-width: 800px) {
  .deliveryCard {
    font-size: 9px;
    font-weight: bold;
    background-color: #fbeed9;
    padding: 4px 7px 4px 7px;
    border-radius: 4px;
    width: 17%;
    text-align: center;
    font-family: 'FordAntenna';
  }
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #2e5991;
  border: none;
  border-radius: 50%;
  color: #fff;
  font-family: 'FordAntenna';
}

.e-date-wrapper .e-date-icon.e-icons {
  box-sizing: border-box;
  color: #2e5991;
}

.ej2DatePicker[_ngcontent-c1] {
  margin-right: 0px !important;
}

//Nome do condutor e do proprietário
.clientName {
  font-family: 'FordAntenna';
  color: #2a3249;
  font-weight: 500;

  &__main {
    font-size: 24px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'FordAntenna';
  }

  &__secondary {
    font-size: 18px;
    color: #1c243c;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    font-family: 'FordAntenna';
    letter-spacing: normal;
  }
}

// informação de proprietário
.ownerBadge {
  font-family: 'FordAntenna';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: inherit;
  letter-spacing: normal;
  color: #596783;
  margin-left: 5px;
}

.hourFont {
  font-size: 16px;
  font-family: 'FordAntenna';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #03377a !important;
}

.carName {
  height: 12px;
  opacity: 0.8;
  font-family: 'FordAntenna';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #133c8e;
}

.mailAndPhone {
  width: 100%;
  font-family: 'FordAntenna';
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #12b664;
}

.textContain1 {
  width: 201px;
  height: 20px;
  font-family: 'FordAntenna';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2c2c;
}

.textContain2 {
  width: 50px;
  height: 20px;
  font-family: 'FordAntenna';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: grey;
  font-size: 13px;
}

.textVehicleModel {
  font-size: 14px;
  font-family: 'FordAntenna';
  font-weight: 500;
  color: #596783;
}

.dateHourFromHome {
  font-family: 'FordAntenna';
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #475776;
}

@media only screen and (max-width: 450px) {
}

@media only screen and (max-width: 450px) {
  .textContain1 {
    width: 219px;
    height: 21px;
    font-family: 'FordAntenna';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2c2c2c;
  }
}

.textContainList {
  font-family: 'FordAntenna';
  font-size: 14px;
  color: #2c2c2c;
}

@media only screen and (min-width: 768px) {
  .listServices {
    list-style-type: disc;
  }
}

// configurando as colunas do checkbox (serviços)
@media only screen and (min-width: 768px) {
  .alignCheckBox {
    border: 1px transparent solid !important;
    display: inline-block !important;
    width: 16em !important;
  }
}

@media only screen and (min-width: 768px) {
  .revisionInput {
    max-width: 30%;
  }
}

@media only screen and (min-width: 768px) {
  .titleMarginT {
    margin-top: none;
  }
}

@media only screen and (max-width: 400px) {
  .titleMarginT {
    margin-top: 14px;
  }
}

.margin-botton {
  margin-bottom: 21px;
}

.fieldsetStyle {
  position: relative;
  font-family: 'FordAntenna';
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  border: none;
  border-radius: 4px;
  border: 1px solid #adb5c5;
  margin-bottom: 20px;
  padding: 5px;
  transition: all 0.2s;
}

.fieldsetStyle.disabled {
  background-color: #efefef;
}

.legendStyle {
  position: absolute;
  font-family: 'FordAntenna';
  top: 0px;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 6px;
  color: #adb5c5;
  border-radius: 4px;
}

.noresize {
  resize: none;
}

.fieldsetStyle:focus-within {
  border-color: #002f6c;
  box-shadow: 0 0 0 0.5px #002f6c;
}
.fieldsetStyle:focus-within legend {
  color: #002f6c;
}

.fieldsetStyle.error {
  border-color: red;
}
.fieldsetStyle.error legend {
  color: red;
}

.card {
  &__header {
    background-color: white;
    border: none;
    padding-top: 30px;
    font-family: 'FordAntenna';
    color: #1b394e;
  }
}

.card-header {
  &__icon {
    width: 34px;
    height: 30px;
    margin: 20px 0;
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.12;
    margin-bottom: 0;
  }
  &__subtitle {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.46;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 450px) {
  .card-header {
    &__icon {
      display: none;
    }
  }
}

@media only screen and (min-width: 769px) {
  .inputStyle {
    margin-top: 20px;
    font-size: 16px;
    border: none;
    color: #2c2c2c;
    outline: none;
    margin-left: 0px;
    padding-left: 6px;
    width: 100%;
  }
  .inputStyle.inputWithIcon {
    width: 85%;
  }
  .inputStyleName {
    margin-top: 20px;
    font-size: 16px;
    border: none;
    color: #2c2c2c;
    outline: none;
    margin-left: 6px;
    width: 93%;
  }
  .inputStylePlate {
    margin-top: 20px;
    font-size: 16px;
    border: none;
    color: #2c2c2c;
    outline: none;
    margin-left: 6px;
    width: 85%;
  }
  .inputStyleRemarks {
    margin-top: 20px;
    font-size: 16px;
    border: none;
    color: #2c2c2c;
    outline: none;
    margin-left: 6px;
    height: 85px;
    width: 90%;
    word-break: break-word;
  }
}

@media only screen and (max-width: 768px) {
  .inputStyle {
    margin-top: 21px;
    font-size: 13px;
    border: none;
    color: gray;
    outline: none;
    margin-left: 6px;
    width: 100%;
  }
  .inputStyle.inputWithIcon {
    width: 85%;
  }

  .inputStyleName {
    margin-top: 21px;
    font-size: 13px;
    border: none;
    color: gray;
    outline: none;
    margin-left: 6px;
    width: 92%;
  }
  .inputStylePlate {
    margin-top: 21px;
    font-size: 13px;
    border: none;
    color: gray;
    outline: none;
    margin-left: 6px;
    width: 82%;
  }
  .inputStyleRemarks {
    margin-top: 21px;
    font-size: 13px;
    border: none;
    color: gray;
    outline: none;
    margin-left: 6px;
    height: 80px;
    width: 594px;
    word-break: break-word;
  }
}

@media only screen and (max-width: 767px) {
  .inputStyle {
    margin-top: 21px;
    font-size: 13px;
    border: none;
    color: gray;
    outline: none;
    margin-left: 6px;
    width: 100%;
  }
  .inputStyle.inputWithIcon {
    width: 85%;
  }
  .inputStyleName {
    margin-top: 21px;
    font-size: 13px;
    border: none;
    color: gray;
    outline: none;
    margin-left: 6px;
    width: 80%;
  }
  .inputStylePlate {
    margin-top: 21px;
    font-size: 13px;
    border: none;
    color: gray;
    outline: none;
    margin-left: 6px;
    width: 58%;
  }
  .inputStyleRemarks {
    margin-top: 21px;
    font-size: 13px;
    border: none;
    color: gray;
    outline: none;
    margin-left: 6px;
    height: 100px;
    width: 80%;
    word-break: break-word;
  }
}

.searchBotton {
  border: none;
  color: #2861a4;
  font-size: 16px;
  background-color: white;
  width: 10%;
  transition: color 0.2s;
}

.searchBotton:hover {
  color: #03a9f4;
  transform: scale(1.2);
}

.searchBotton:focus {
  outline: none;
}

/* Icone usado em agendamentos confirmados */
@media screen and (min-width: 768px) {
  .iconCheck {
    width: 20px;
    position: relative;
    left: 140px;
    margin-top: -16px;
  }
}

@media screen and (min-width: 1326px) {
  .iconCheck {
    width: 20px;
    position: relative;
    left: 140px;
    margin-top: -15px;
  }
}

@media screen and (min-width: 1800px) {
  .iconCheck {
    width: 20px;
    position: relative;
    left: 140px;
    margin-top: -18px;
  }
}

@media screen and (max-width: 767px) {
  .iconCheck {
    position: relative;
    left: 64px;
    top: -32px;
    width: 20px;
  }
}

@media screen and (min-width: 576px) {
  .iconAdd {
    width: 23px;
    position: relative;
    left: 77px;
    margin-top: -33px;
  }
}

@media screen and (max-width: 380px) {
  .iconAdd {
    position: relative;
    left: 54px;
    top: -29px;
    width: 23px;
  }
  .marginDelivery {
    margin-top: 11px;
  }
}

@media screen and (min-width: 1325px) {
  .navWidth {
    position: relative;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .navWidth {
    position: relative;
    width: auto;
  }
}

@media screen and (max-width: 575px) {
  .iconAdd {
    position: relative;
    left: 62px;
    top: -33px;
    width: 23px;
  }
  .marginDelivery {
    margin-top: 11px;
  }
  .deliveryCard {
    font-size: 10px;
  }
}

.tabBtn {
  background-color: #002f6c;
  color: white;
  border: solid 2px #002f6c;
  padding: 5px 40px 5px 40px;
  border-radius: 4px;
  margin-right: 20px;
}

// for tablet
@media only screen and (max-width: 768px) {
  .scheduleIcon {
    width: 14%;
    padding: 0px 1px 7px 1px;
  }

  .tabBtn {
    background-color: #002f6c;
    color: white;
    border: solid 2px #002f6c;
    padding: 5px 35px 5px 35px;
    border-radius: 4px;
  }
}

// for phone
@media only screen and (max-width: 400px) {
  .titleMarginB {
    margin-bottom: 4px;
  }
  .scheduleIcon {
    width: 10%;
    padding: 0px 1px 7px 1px;
  }
  .tabBtn {
    background-color: #002f6c;
    color: white;
    border: solid 2px #002f6c;
    padding: 5px 15px 5px 15px;
    border-radius: 4px;
  }

  .btnCenter {
    text-align: center;
  }
}

// __________________________________________ Checklist __________________________________________//

@media screen and (min-width: 768px) {
  .btnDeleteOrBack {
    background-color: #dce5f4;
    color: #103a77;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'FordAntenna';
    padding: 10px;
    border: solid 2px;
    margin-left: 5px;
    margin-right: 5px;
    width: 147px;
  }

  .btnContinue {
    background-color: #002f6c;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'FordAntenna';
    padding: 10px;
    border: solid 2px #002f6c;
    margin-left: 5px;
    margin-right: 5px;
    width: 147px;
  }
}

@media screen and (max-width: 767px) {
  .btnDeleteOrBack {
    background-color: #dce5f4;
    color: #103a77;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'FordAntenna';
    padding: 10px;
    border: solid 2px;
    margin-left: 5px;
    margin-right: 5px;
    width: 147px;
  }

  .btnContinue {
    background-color: #002f6c;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'FordAntenna';
    padding: 10px;
    border: solid 2px #002f6c;
    margin-left: 5px;
    margin-right: 5px;
    width: 147px;
  }
}

// car-img-checklist
.carWebChecklist {
  width: 50%;
  background-color: white;
  border-radius: 14px;
}
.titleChecklist {
  height: auto;
  font-family: 'FordAntenna';
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #1b394e;
}

.subtitleCheckList {
  height: auto;
  font-family: 'FordAntenna';
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #1b394e;
}

.iconCheckList {
  width: 34px;
  height: 30px;
}

@media screen and (max-width: 450px) {
  .centerTitle {
    text-align: unset;
  }

  .iconCheckList {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .centerTitle {
    text-align: center;
  }
}
// breadcrumb
.breadcrumb {
  display: inline-flex !important;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  // background-color: #f4f8ff !important;
  border-radius: 0.25rem;
}
.breadcrumb-circle-active {
  color: #fff;
  margin-right: -9px;
  border: 2px solid #002f6c;
  border-radius: 15px;
  width: 20px;
  height: 20px;
}

.breadcrumb-line-active {
  color: #002f6c !important;
}
.breadcrumb-line-active::before {
  display: inline-block;
  padding-right: 0rem;
  color: #002f6c !important;
  content: '───';
}

.breadcrumb-line-active + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0px;
  color: #d2d2d3;
  content: '───';
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.57rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0rem;
  color: #dae0ed;
  content: '───';
}

.bread-marker-active,
.bread-marker-in-progress {
  transition: opacity 0.2s;
}

.bread-marker-active:hover,
.bread-marker-in-progress:hover {
  opacity: 0.5;
  cursor: pointer;
}

// Modal Items
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 420px !important;
    margin: 1.75rem auto;
  }
}

.modal-header::before {
  content: '';
  height: 8px;
  background-color: #002f6c;
  width: 100%;
  transform: translateY(-20px);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  position: absolute;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'FordAntenna';
  padding: 1rem 1rem;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-header {
  display: flex;
  justify-content: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modalItem {
  font-family: 'FordAntenna';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #a2adc4;
}

.btnModal {
  width: 130px;
  height: 40px;
  background-color: white;
  border-radius: 3px;
  border: solid 2px #6f7277;
  color: #002f6c;
  font-weight: 500;
}

.btnConfModal {
  width: 130px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(200, 213, 236, 0.8);
  background-color: #002f6c;
  color: white;
}

.card {
  width: 100% !important;
}

.type-btn-limpar {
  width: 147px;
  height: 50px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(200, 213, 236, 0.8);
  background-color: #0276b3;
  border-color: #0276b3;
}

.image-box {
  padding: 0px;
  float: left;
  margin-right: 6px;
}

.checklist-btn-status {
  display: block;
  width: 260px;
  float: left;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 2px;
}
.checklist-btn-status-size {
  height: 18px;
  width: 18px;
}

.checklist-status-list li {
  margin-bottom: 6px;
}

@media only screen and (max-width: 1366px) {
  .text-box {
    margin-top: 7px;
    margin-left: 10px;
    font-family: 'FordAntenna';
    font-size: 13.6px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #475776;
  }
}

@media only screen and (min-width: 1367px) {
  .text-box {
    margin-top: 7px;
    margin-left: 10px;
    font-family: 'FordAntenna';
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #475776;
  }
}

.align-btn-status li {
  float: left;
  margin: 5px;
  height: 18px;
}

.subTitles {
  margin-top: 10px;
}

.containerSubTitles {
  border: 1px solid #000;
  width: 350px;
  height: 356px;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(200, 213, 236, 0.8);
  background-color: #fff;
}

.containerSubTitlesIcon {
  margin: 20px 0;
}

.checklist-type-item {
  width: 182px;
  height: 19px;
  font-family: 'FordAntenna';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #1b394e;
}

.checklist-type-item-title {
  width: 228px;
  height: 19px;
  font-family: 'FordAntenna';
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1b394e;
}

.checklist-type-btn-limpar {
  height: 19px;
  font-family: 'FordAntenna';
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #2862a4;
  cursor: pointer;
}

.card-columns {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.line {
  height: 1px;
  border: solid 1px #e5ecf8;
  margin-top: 10px;
  margin-bottom: 10px;
}

.type-input-text {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #eef4ff;
  border-radius: 0;
}

.type-width-50 {
  width: 50px;
}

.type-label-sulcos {
  width: 51px;
  height: 19px;
  font-family: 'FordAntenna';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #475776;
  margin-left: 15px;
}

.btnSave,
.btnFinish,
.btnBack {
  border: solid 2px #002f6c;
  border-radius: 4px;
  padding: 10px;
  margin-right: 20px;
  width: 147px;
}

.btnSave,
.btnFinish {
  background-color: #002f6c;
  color: white;
}

.btnBack {
  background-color: #dce5f4;
  color: #002f6c;
}

// for Ipad
@media only screen and (min-width: 501px) {
  .showBtnMobile {
    display: none;
  }
}

// for desktop | WEB
@media only screen and (min-width: 1200px) {
  .showBtnMobile {
    display: none;
  }
}

.type-mm {
  width: 27px;
  height: 19px;
  font-family: 'FordAntenna';
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #b9c3d7;
}

.card {
  box-shadow: 0px 0px 10px #dee7f6;
  border: none;
  border-radius: 10px;
}

.type-assign-footer {
  width: 225px;
  height: 27px;
  font-family: 'FordAntenna';
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #b9c3d7;
}

.type-assign {
  width: 100%;
  height: 19px;
  font-family: 'FordAntenna';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #1b394e;
  text-align: center;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: solid 1px #b9c3d7;
}

.card-page-header {
  min-height: 282px;
  padding-top: 1px;
}

.card-columns {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}

@media only screen and (max-width: 450px) {
  .card-page-header {
    min-height: 238px;
    padding-top: 1px;
  }
}

@media only screen and (max-width: 560px) {
  .type-btn-finalizar-full {
    width: 335px;
    height: 50px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(200, 213, 236, 0.8);
    background-color: #002f6c;
    border-color: #002f6c;
  }
}

@media only screen and (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .container {
    max-width: 95%;
  }
}

@media only screen and (min-width: 1100px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .container {
    max-width: 95%;
  }
}

@media only screen and (min-width: 1200px) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .container {
    max-width: 95%;
  }
}

@media (min-width: 360px) {
  .card-columns .card {
    display: inline-block;
    width: 150%;
  }
}

.iconFilter {
  margin-right: 8px;
  margin-top: 4px;
  width: 14px;
  height: 14px;
}

// usada para limitar os caracteres visíveis e não quebrar o layout
.text-no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.text-width-schedules {
  width: 100%;
}

@media (max-width: 1103px) {
  .align-date {
    margin-top: 15px;
  }
}

@media (max-width: 1103px) {
  .date-line {
    margin-bottom: -14px;
  }
}

@media (max-width: 692px) {
  .hour-line {
    margin-top: 15px;
  }
}

@media (min-width: 693px) {
  .hour-line {
    display: block;
  }
}

@media (min-width: 400px) {
  .line-date {
    display: block;
  }
}

@media (max-width: 1103px) {
  .line-date {
    margin-top: 15px;
  }
}

.title-app {
  color: #2a3249;
  font-family: 'FordAntenna';
  font-size: 18px;
  font-weight: 600;
  margin-top: 1px;
  margin-bottom: 25px;
}
@media (max-width: 330px) {
  .col-width {
    max-width: 60%;
    margin-bottom: 5px;
  }
}

@media (min-width: 331px) {
  .col-width {
    max-width: 68%;
    margin-bottom: 5px;
  }
}

@media (min-width: 480px) {
  .col-width {
    max-width: none;
    margin-bottom: 5px;
  }
}

.text-center {
  text-align: center;
  margin-top: 30px;
}
