  // @import "primeicons/primeicons.css";
  // * {
  //   border: none;
  //   margin: 0;
  //   padding: 0;
  //   font-family: "FordAntenna";
  // }
  * {
    font-family: 'Ford F-1';
  }

  @media only screen and (min-width: 1920px) {
    html {
      background-color: #F4F4F4;
    }
  }