  *{
    margin: 0;
    padding: 0;
  }
  @media only screen and (min-width: 1920px) {
  html {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  body {
    width: 1920px;
  }
}
