/* You can add global styles to this file, and also import other style files */
@import '../css/general-font.css';
@import '../css/table-dom.css';
@import '../../../../../node_modules/@angular/material/prebuilt-themes/azure-blue.css';
@import '../css/material-override.scss';
@import '../css/general-color-button.css';

/* Importing Bootstrap SCSS file. */
@import '../../../../../node_modules/bootstrap/scss/bootstrap';

.hline {
    margin: 0px -15px;
    color: #e1e9f8;
    opacity: 1;
}